<template>
  <div class='container'>

    <el-row style="margin-bottom:40px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/application' }">应用中心</el-breadcrumb-item>
        <el-breadcrumb-item >打卡月报</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <nav class="report_nav">
      <ul>
        <li  >
          <router-link   to="/application-clock-report-day">按日统计</router-link>
        </li>

        <li >
          <router-link class="active" to="/application-clock-report-month">按月统计</router-link>
        </li>
      </ul>
    </nav>
    <div class="report_main">
      <el-form :inline="true" :model="searchFormData" class="demo-form-inline">
        <el-form-item label="项目选择">
          <el-select
              v-model="searchFormData.project_id" placeholder="全部"
                clearable
                filterable
                style="width:100%"
                @change="selectProject"
              >
              <el-option
                v-for="item in projectDataList"
                :key="item.project_id"
                :label="item.name"
                :value="item.project_id"
              />
          </el-select>
        </el-form-item>

        <el-form-item label="职位选择">
          <el-select
              v-model="searchFormData.job_id" placeholder="全部"
                clearable
                filterable
                style="width:100%"
              >
              <el-option
                v-for="item in jobDataList"
                :key="item.job_id"
                :label="item.title"
                :value="item.job_id"
              />
          </el-select>
        </el-form-item>

        <el-form-item label="开始时间">
            <el-date-picker
              v-model="searchFormData.min_time"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
        </el-form-item>

        <el-form-item label="结束时间">
            <el-date-picker
              v-model="searchFormData.max_time"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
        </el-form-item>


        <el-form-item label="姓名">
          <el-input  placeholder="用户名" v-model="searchFormData.name"></el-input>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
      </el-form>


        <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >

          <el-table-column align="center" label="日期" prop="adname"> </el-table-column>
          <el-table-column align="center" label="姓名"  prop="address"/>
         
          <el-table-column align="center"  label="手机号码" prop="all_num" />
          <el-table-column align="center"  label="上班打卡时间" prop="all_num" />
          <el-table-column align="center"  label="上班打卡图片" prop="all_num" />
          <el-table-column align="center"  label="上班打卡状态" prop="all_num" />
          <el-table-column align="center"  label="下班打卡时间	" prop="all_num" />
          <el-table-column align="center"  label="下班打卡图片" prop="all_num" />
          <el-table-column align="center"  label="下班打卡状态" prop="all_num" />

         
      </el-table>
      <div style="text-align:center;padding:20px">
        <!--分页-->
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      
    </div>
  </div>
    
</template>

<script>

//mport { recruitList,jobList,projectList,updateEnroll} from "@/api/director/work/recruit/index";
//import { clockMonthList} from "@/api/director/workmaster/index";

import { jobList,projectList,leaveList,leaveInfo,clockDayList} from "@/api/application/index";

  export default {
    name: "director-workmaster-clock-month",
    data() {
      return {
       
        searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          project_id:"",
          job_id:"",
          min_time:"",
          max_time:"",
          name:"",
          page:0,
          limit:0
        },

      projectDataList:[],
      jobDataList:[],


      }
    },

    mounted() {
     // this.fetchData();
      this.initProjectList();
    },


    methods: {
      //项目列表
      initProjectList(){
      var that = this;
      projectList({}).then((response) => {
        console.log('projectList',response);
        that.projectDataList = response.data;
        that.searchFormData.project_id=that.projectDataList[0].project_id;
        this.initJobList();

      });
      
    },
    

    //职位列表
    initJobList(){
      var that = this;
      jobList({
        project_id:this.searchFormData.project_id
      }).then((response) => {
        console.log('jobList',response);
        that.jobDataList = response.data;
        that.searchFormData.job_id=that.jobDataList[0].job_id;
        this.fetchData();

      });
      
    },


    selectProject(e){
      console.log(e)
      this.searchFormData.project_id=e;
      this.initJobList();

    },
    



    search(){
      this.fetchData();
    },

    fetchData(){
      var that = this;
      that.searchFormData.page=that.pageProps.pageNum;
      that.searchFormData.limit=that.pageProps.pageSize;

      clockMonthList(that.searchFormData).then((response) => {
        console.log(response);
        if(response.code==200){
          that.pageProps.records = response.data.data;
          that.pageProps.total = response.data.total;
        }else{
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      
      });
    },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },
      
    }
  }
</script>


<style lang="scss" scoped>
.report_nav {
  // height: 72px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 19px;
  ul {
    display: flex;

    li {
      position: relative;
      a {
        padding: 0 24px;
        display: block;
        font-size: 16px;
        &.router-link-active {
          //background-color: #4c71db;
          border-bottom: 1px solid #4c71db;
          color:#4c71db ;
          font-weight: bold;
          //color: #fff;
        }
      }
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }

   
  }
}
.report_main {
  border-radius: 6px;
  
}
</style>